/* global THREE */
import shapepng from "./image/shape.png";
import {
  svgToShape,
  svgResize
} from './common'; //eslint-disable-line

export default class PlotBoard {
  constructor(root) {
    this._root = root;
    this._status = root.status;
    this.ready = false;
    //
    this.selectId = 0;
    this.scrollAreaId = 0; // 表示中のエリア（スクロール）
    //
    // ボードの設定・オブジェクトを格納する配列
    this.boards = {
      canvas: null,
      ctx: null,
      size: 300, // ボードのサイズ（px）
      total: 9, // ボードの合計数（分割数）
      positions: []
    };
    //
    this.offsetX = (this._root.status.mobile === true) ? -150 : 30;
    this.offsetY = (this._root.status.mobile === true) ? 250 : 0;
    //
    this.count = 0;
    //
    this.positions = [];
    //
    // 集合図形のステップのパターンを設定
    this.stepPattern = {
      top: [ // 座標トップ時のアニメーション設定
        [0, 50],
        // [6, 550],
        [0, 50],
        // [7, 250],
        [0, 50],
        [1, 250],
        [0, 50],
        [2, 250],
        [0, 50],
        [3, 250],
        [0, 50],
        [4, 250],
        [0, 50],
        [5, 250],
      ],
      other: [
        [0, 0, 0]
      ],
      web: [
        [1, 50, 30]
      ],
      ui: [
        [2, 50, 30]
      ],
      graphic: [
        [4, 50, 30]
      ],
      about: [
        [3, 50, 30]
      ],
      works: [
        [5, 50, 30]
      ],
      blog: [
        [8, 50, 30]
      ],
      scroll: [ // スクロール時の設定
        [0, 30, 0], // デフォルト座標(-140)
        [2, 50, 20],
        [5, 70, 20],
        [3, -90, 20],
        [4, 70, 20],
        [4, 70, 20],
        [4, 70, 20]
      ],
      scroll_sp: [ // SPスクロール時の設定
        [0, -150, 250], // デフォルト座標
        [2, 0, 250],
        [5, 0, 250],
        [3, 0, 250],
        [4, 0, 250],
        [4, 0, 250],
        [4, 0, 250]
      ]
    };
    //
    // [a, b, c]
    // a : getPlotShapes で設定したパスデータの番号
    // b : パスデータ拡大率（1が100%）
    // c : 出現率
    this.plots = {
      setting: [
        [0, 1, 20], // Z
        [1, 1, 1], // 顔
        [2, 1, 20], // m
        [3, 1, 200], // 骨
        [4, 0.8, 20], // ㍑
        [5, 1, 20], // 64
        [6, 1, 100], // 4●
        [7, 1, 4000], // ●
        [36, 1, 500], // ●2.0
        [8, 1, 10], // ▲窓
        [9, 1, 20], // ギザ7
        [10, 1, 300], // ▲
        [11, 1, 50], // ブロック◼
        [12, 1, 20], // ギザ棒
        [13, 0.8, 50], // 砂時計
        [14, 1, 100], // ＊
        [15, 0.8, 100], // ◼
        [16, 1, 50], // 2●
        [17, 1, 100], // U
        [18, 1, 10], // ん
        [19, 1, 200], // ハート
        [20, 0.8, 50], // 10●
        [21, 1, 50], // ●窓
        [22, 1, 50], // レモン
        [23, 1, 100], // Y
        [24, 0.8, 50], // 直角▲
        [25, 1, 10], // ◆窓
        [26, 0.8, 100], // 十
        [27, 0.8, 20], // 台型
        [28, 1, 100], // 洋梨
        [29, 0.8, 50], // 棒
        [30, 1, 50], // L
        [31, 0.6, 1], // syngram
        [32, 1, 10], // コマ
        [33, 1, 20], // ●3
        [34, 1, 10], // x丸
        [35, 0.8, 50], // ◼◼
        [37, 1, 10], // ヒトデ
        [38, 1, 10], // 金米糖
        [39, 1, 10], // ●台
        [40, 1, 10], // ポコ菱
        [41, 1, 10], // 嘴
        [42, 1, 10], // シャベル
        [43, 1, 10], // 太長四角
        [44, 1, 20], // ●トライ
        [45, 1, 10], // ス
        [46, 0.6, 20], // 細十
        [47, 0.8, 50], // 餅
        [48, 1, 20], // サドル
        [49, 1, 10], // M
        [50, 1, 500], // ▲2.0
        [51, 1, 20], // ばかうけ
        [52, 1, 20], // ラグビー
        [53, 0.8, 20], // ピック
        [54, 1, 10], // 細口
        [55, 0.6, 20], // バラン
        [56, 0.6, 20], // 鳥
        [57, 1, 20], // ピーナッツ
        [58, 0.8, 10], // やわ棒
        [59, 0.6, 10], // 団子棒
        [60, 0.8, 10], // gem
        [61, 0.8, 10], // 肉
        [62, 0.8, 100], // ◼
        [63, 0.5, 1], // たけのこ
        [64, 0.5, 1], // きのこ
        [65, 0.8, 10], // U-
        [66, 1, 5], // m-
      ],
      stock: [],
      data: []
    };
  }

  // PNG読み込み
  init() {
    return new Promise((resolve) => {
      this.boards.canvas = document.createElement("canvas");
      this.boards.canvas.width = this.boards.size;
      this.boards.canvas.height = this.boards.size * this.boards.total;
      this.boards.ctx = this.boards.canvas.getContext("2d");
      //
      const imageObj = new Image();
      imageObj.onload = () => {
        this.boards.ctx.drawImage(imageObj, 0, 0, this.boards.size, this.boards.size * this.boards.total);
        resolve();
      };
      imageObj.src = shapepng;
      //
      this._initPlotGeometrys();
      this.ready = true;
    });
  }

  // 穴子が投げるオブジェクトのGeometryを生成する
  _initPlotGeometrys() {
    const shapes = this.getPlotShapes();
    this.plots.setting.forEach((item) => {
      const path = (item[1] !== 1) ? svgResize(shapes[item[0]], item[1]) : shapes[item[0]];
      const shape = svgToShape(path, -5, 5, 0.15);
      this.plots.data.push(new THREE.ShapeGeometry(shape));
    });
  }

  update() {
    this._updateScroll();
    this.extenuatePositions();
    if (this.scrollAreaId !== 0) return;
    this.count++;
    const step = this.getTopStepSetting();
    const page = this._root.status.pageId;
    if (this.count > step.wait) {
      this.count = 0;
      this.selectId++;
      if (this.selectId >= this.stepPattern[page].length) this.selectId = 0;
      return true;
    } else {
      return false;
    }
  }

  _updateScroll() {
    if (this._root.isScrollChanged() === false) return;
    const scrollY = window.scrollY;
    if (this._root.status.pageId !== "top") {
      this._root.status.boardScrollY = Math.floor(scrollY);
    } else {
      const height = window.innerHeight - 100;
      const listH2 = document.getElementsByTagName("section");
      const nodeH2 = Array.prototype.slice.call(listH2, 0);
      const scrollSetting = this.getScrollStepSetting();
      let num = 0;
      //
      if (this._status.scrollTop > 100) {
        nodeH2.forEach((el) => {
          const elpos = el.getBoundingClientRect();
          const offsetY = elpos.top - height + scrollY;
          if (this._status.scrollTop > offsetY) num++;
        });
      }
      //
      num--;
      if (num < 0) num = 0;
      if (num > 6) num = 6;
      if (this.scrollAreaId !== num) {
        const lastPtn = scrollSetting[this.scrollAreaId];
        this.scrollAreaId = num;
        this.offsetX = scrollSetting[num][1];
        this.offsetY = scrollSetting[num][2];
        if (lastPtn[0] !== scrollSetting[num][0]) {
          this._root.updateBallsDestination();
        }
      } else {
        return false;
      }
    }
  }

  getScrollStepSetting() {
    if (this._root.status.mobile === false) {
      return this.stepPattern.scroll;
    } else {
      return this.stepPattern.scroll_sp;
    }
  }

  getTopStepSetting() {
    const pageId = this._root.status.pageId;
    if (pageId !== "top") {
      const set1 = this.stepPattern[pageId][this.selectId];
      this.offsetX = set1[1];
      this.offsetY = set1[2];
      return {
        shape: set1[0],
        wait: 1000
      };
    } else if (this.scrollAreaId === 0) {
      const set1 = this.stepPattern[pageId][this.selectId];
      return {
        shape: set1[0],
        wait: set1[1]
      };
    } else {
      const set2 = this.getScrollStepSetting()[this.scrollAreaId];
      return {
        shape: set2[0],
        wait: 1000
      };
    }
  }

  getRandomPlotGeometry() {
    if (this.plots.stock.length === 0) {
      this.plots.setting.forEach((item, num) => {
        for (let i = 0; i < item[2]; i++) this.plots.stock.push(num);
      });
    }
    const num = Math.floor(Math.random() * this.plots.stock.length);
    const id = this.plots.stock[num];
    this.plots.stock.splice(num, 1);
    return this.plots.data[id];
  }

  // positions データをリセットして更新する
  resetPos() {
    this.positions = [
      [],
      [],
      [],
      [],
      []
    ];
    const shiftX = Math.floor(Math.random() * 3);
    const shiftY = Math.floor(Math.random() * 3);
    if (this.ready === false) return;
    const step = this.getTopStepSetting();
    //
    const array = this.boards.ctx.getImageData(0, this.boards.size * step.shape, this.boards.size, this.boards.size).data;
    for (let i = 0; i < array.length; i++) {
      if (this.isActivePosition(i, shiftX, shiftY) === true) {
        if (array[i] < 200) {
          const num = Math.floor(i / 4);
          const x = num % this.boards.size;
          const y = this.boards.size - Math.floor(num / this.boards.size);
          const t = Math.floor(array[i] / 40);
          this.positions[t].push([x, y]);
        }
      }
    }
  }

  isActivePosition(i, shiftX, shiftY) {
    if (i % 4 !== 0) return false;
    const num = Math.floor(i / 4);
    const vx = num % 300;
    const vy = Math.floor(num / 300);
    if (vx % 3 !== shiftX) return false;
    if (vy % 3 !== shiftY) return false;
    return true;
  }

  // タイプを指定してランダムに選出したピクセルの座標を取得
  getDestination(type) {
    const vt = (this.positions[type].length === 0) ? 0 : type;
    //
    const length = this.positions[vt].length;
    if (length === 0) {
      this.resetPos();
      return this.getDestination(type);
    }
    //
    const select = Math.floor(Math.random() * length);
    const pos = this.positions[vt][select];
    this.reduceNearPositions(pos[0], pos[1]);
    return {
      x: pos[0] / 2.5 + this.offsetX,
      y: pos[1] / 2.5 + this.offsetY
    };
  }

  reduceNearPositions(x, y) {
    const length = this.positions.length;
    for (let i = 0; i < length; i++) {
      this.positions[i] = this.positions[i].filter((q) => {
        if (Math.abs(q[0] - x) > 12) return true;
        if (Math.abs(q[1] - y) > 12) return true;
        return false;
      });
    }
  }

  extenuatePositions() {
    let type = 4;
    while (type > 0 && this.positions[type].length === 0) type--;
    const length = this.positions[type].length;
    if (length === 0) return;
    const select = Math.floor(Math.random() * length);
    const pos = this.positions[type][select];
    this.reduceNearPositions(pos[0], pos[1]);
  }

  // 穴子が投げるオブジェクトのパスデータ
  getPlotShapes() {
    return {
      0: "M52.52,31.67c-1.55-10.18-17.95-4.81-20.34-9.58c-0.99-1.98,5.39-14.2-3.08-15.19c-7.99-0.93-15.69,26.13-14.75,29.5c1.65,5.94,12.44,5.72,18.06,3.63c-0.87,2.64-5.8,12-1.98,15.74C35.7,60.95,54.02,41.55,52.52,31.67z",
      1: "M47.55,28.24c-1.17-8.21-8.73-12.43-15.91-12.21l-4.08,2.49c0.99,0.13,1.61,0.79,1.61,0.79L27.97,20c-1.95-1.15-3.94,0.6-3.79,2.33c0.15,1.86,1.91,2.69,3.59,2.02c1.12-0.44,2.14-2.91,0.2-4.35l1.2-0.69c1.81,1.64,0.78,3.89,0.82,3.87l3.21-1.32c0.18-1.82,1.58-3.45,4.33-3.26l-0.52,1.02c-0.71-0.04-2.56,0.41-2.48,2.48c0.05,1.37,1.25,2.38,2.52,2.35c1.6-0.03,2.56-0.97,2.42-2.54c-0.01-0.14-0.19-2.15-2.45-2.29l0.52-1.02c4.65,0.81,4.36,6.96-0.26,6.92c-2.23-0.02-4.21-1.61-4.07-3.66c0,0-3.2,1.32-3.21,1.33c-0.37,0.93-0.94,2.03-3.28,2.21c-1.43,0.11-3.48-0.31-3.83-2.37c-0.54-3.21,2.39-4.84,4.65-4.51l4.09-2.49c-9.37,0.41-18.94,6.73-15.01,19.51c2.26,7.36,8.69,11.93,17.81,10.09C39.56,44.6,49.42,41.31,47.55,28.24z",
      2: "M47.68,30.24c-0.44-4.74-3.82-12.82-13.17-11.29c-7.1,1.16-8.11,7.93-8.11,7.93c-3.09-6.09-8.6-6.29-11.12-5.41C7.4,24.24,6.57,37.15,9.4,43.36c2.18,4.76,5.52,6.09,7.86,5.83c6.32-0.7,6.14-10.93,6.14-10.93S25.33,43,30,43.01c4.87,0.01,7.25-2.28,8.87-6.28c4.23,7.86,13.75,6.04,18.22,0.22C63.32,28.83,52.74,20.83,47.68,30.24z",
      3: "M45.9,20.49c0.23-2.87-2.94-9.81-10.19-10.42c-4.8-0.4-13.3,0.18-14.42,8.15c-0.68,4.83,2.34,8.38,6.04,8.83c-0.01,0.16-2.47,9.85-2.42,10.72c-5.06,0.49-7.1,2.49-7.22,6.93c-0.09,3.04,2.01,8.89,10.78,8.96c6.48,0.05,11.19-3.44,11.57-7.14c0.42-4.06-1.54-6.85-4.41-7.01l2.49-11.4C42.2,28.11,45.53,25.23,45.9,20.49z",
      4: "M49.22,41.47c-2.17-0.36-4.51-0.82-6.77-1.53c1.64-1.53,3.18-3.18,4.5-5.04c0.07-0.09,0.13-0.19,0.19-0.29c-3.72-2.34-7.68-4.9-11.22-7.06c-1.28,1.54-2.88,3.01-3.67,3.66c-1.06-3.69-0.73-8.68,3.98-8.34c2.7,0.19,1.52,2.5-0.31,4.68c3.54,2.16,7.49,4.72,11.22,7.06c3.27-4.73,5.64-10.78,3.29-16.35c-2.27-5.39-7.7-8.56-13.46-8.75c-11.99-0.4-20.23,10.57-18.5,21.96c0.22,1.48,0.59,2.86,1.05,4.19c-1.04,0.01-2.1-0.02-3.16-0.13c-8.56-0.92-8.5,12.48,0,13.4c4.24,0.46,8.47,0,12.48-1.17c4.86,3.47,10.84,5.65,16.84,6.64C54.09,55.8,57.71,42.89,49.22,41.47z",
      5: "M54.4,23.6c-0.2-2.9-2.4-5.4-5.4-5.4c-2.8,0-5.7,2.5-5.4,5.4c0.1,1.5,0.1,3-0.1,4.5c-0.7,0.2-1.3,0.4-1.8,0.8c-1.6-3.8-2.7-7.7-3.5-11.9c-1-5.8-8.8-4.4-10.5,0c-1.5,4-2.6,8-3.4,12.1c-0.6-0.2-1.2-0.3-1.9-0.5c-1.2-1.3-1.7-2.6-1.6-4.7c0.4-6.2-9.1-7.3-10.7-1.4c-1.5,5.6-0.1,11.3,3.2,16c1.2,2.6,3.3,4.7,5.8,6.2c6.5,5.2,15.3,7.7,22.7,5c5.1-1.8,10.6-7.3,10.8-13.2C54.1,32.4,54.7,28.1,54.4,23.6z",
      6: "M47.83,18.22c-3.79-5.42-12.58-3.17-14.74,3.44C30.9,15,24.71,12.93,20.1,16.34c-8.85,6.54-0.6,14.75,3.03,15.15c-5.15,1.16-8.93,7.82-4.59,13.86c2.85,3.97,12.18,3.73,13.84-3.03c0.79,1.9,6.1,8.36,13.29,3.39c8.3-5.73,1.68-13.67-0.82-14.73C47.35,30.77,52.93,25.52,47.83,18.22z",
      7: "M32.76,16.07c-9.38,0.17-19.86,6.87-15.93,19.64c2.26,7.36,8.69,11.93,17.81,10.09c5.12-1.03,14.98-4.32,13.12-17.39C46.58,20.2,41.68,15.91,32.76,16.07z",
      8: "M50.6,36.8c-2-2.3-10.6-14.6-13.4-18.6c-7.1-10.3-9.1-7.6-14.1,3.4c-3.4,7.4-7.3,16.7-9.4,20.9c-3.2,6.4,2,7,8.7,5.8c2.1-0.4,4.5-0.8,6.9-1.1V40l-7.1,0.9L30.6,22l12.8,16.3L29.3,40v7.1c8.5-1.3,17.6-2.3,20-2.4C55.2,44.3,54,40.5,50.6,36.8z",
      9: "M46.48,31.28c0.39-0.43,7.08-6.75,6.21-8.73c-0.6-1.38-11.22-1.03-11.23-1.05c-0.25-0.52-3.18-12.24-5.7-12.36c-2.39-0.12-6.38,9.95-6.52,10.16c-1.57-0.36-15.04-5-16.86-2.96C10.62,18.31,19,29.26,19.27,29.8c0,0.01,0,0.01,0.01,0.02c-0.99,0.6-9.96,4.13-9.52,6.99c0.38,2.5,11.83,3.42,12.02,3.44c-0.12,0.76-1.67,10.81,0.14,11.91c1.64,0.99,8.09-6.16,8.47-6.49c0.1,0.11,6.99,10.01,9.44,9.17c1.68-0.58,1.68-12.19,1.75-12.4c0.24,0.05,12.02,1.96,12.81,0.04C55.34,40.14,46.55,31.34,46.48,31.28z",
      10: "M27.52,13.35c-4.51,0.73-12.87,23.94-11.73,32.09c0.71,5.08,33.23-0.98,34.53-5.21C51.77,35.54,34.53,12.21,27.52,13.35z",
      11: "M54.83,27.06c-2.78-1.04-8.25,0.09-8.35,0.1c0-0.16,0.41-9.02-1.64-10.18c-2.41-1.38-8.03-0.34-8.01-0.39c0.36-1.09,0.32-6.63,0.06-7.37c-0.86-2.47-8.37-2.06-9.42-0.92c-2.2,2.39-0.96,8.65-0.94,9.34c-0.62-0.19-6.89-1.56-8.42,0.01c-1.51,1.55-0.5,8.11-0.5,8.72c-1.11,0.09-6.56-0.13-8.57,0.57c-3.24,1.13-1.96,9.6,0.24,9.99c2.31,0.41,7.8-0.18,8.15-0.2c-0.13,0.48-1.18,8.74,0.77,9.93c1.59,0.97,9.42,0.19,9.53,0.17c-0.03,0.72-0.84,7.48-0.51,8.6c0.94,3.19,8.47,2.04,9.16,1.23c1.39-1.64,0.81-9.3,0.82-9.91c0.83,0.14,6.68,0.67,8.71-0.51c1.48-0.85,0.3-8.55,0.17-9.75c0.02,0,6.09,0.52,9.23-0.48C56.75,35.56,57.37,28.01,54.83,27.06z",
      12: "M51.8,25.6c-1.3,1.7-3.9,4.8-5.5,7.1c-2.1-2.2-4-4.3-6.6-7.1c-1.9-2-4.7-1.1-6,0.5c-2.3,2.9-5.1,6.3-5.9,7.5c0,0-4.5-4.5-7.2-7.2c-1.4-1.4-4.2-1.1-5.5,0.8c-1.7,2.6-5.6,7.4-7.3,10.8c-2,3.9,3,6.3,5.2,3.2c2-2.8,4.7-7,5.4-8.1c1.1,1.2,4.8,5.1,7.1,7c2.4,2,4.4,1.6,5.5,0.1c2-2.8,5.1-7.3,6.1-8.7c1.5,1.8,5.7,6.2,7.2,7.8c1.6,1.6,3.8,1.1,4.7-0.2c2-2.6,6.1-6.9,7.7-9.4C58.7,26.2,54.6,21.7,51.8,25.6z",
      13: "M46.5,16.7c-3.6,2.8-14,13.1-14,13.1s-8.5-11.1-10.9-13.9c-5.9-7.2-11.4-3.6-11.4,5.3c0,6.3-0.1,18.9,0.2,25.2c0.5,9.7,7.1,8.9,11,4.6c2.9-3.1,11.3-13.4,11.3-13.4s8.2,9,11.4,11.6c5.7,4.5,10.5,5,11-4.6c0.3-5.6,0.4-16.7,0.3-22.3C55.5,15,51.9,12.4,46.5,16.7z",
      14: "M49,34c-2.3-1.2-4.7-2.4-7-3.6c1.9-1.6,3.9-3.2,5.8-4.8c5.9-4.9-2.6-13.2-8.4-8.4c-0.8,0.7-1.6,1.3-2.4,2l0-1.5c-0.1-7.6-12-7.6-11.8,0c0,1.2,0,2.4,0.1,3.6c-1.4-0.8-2.9-1.6-4.3-2.4c-6.7-3.7-12.6,6.5-6,10.2c1.9,1.1,3.9,2.1,5.8,3.2c-1.9,1.5-3.7,2.9-5.6,4.3c-6,4.6-0.1,14.9,6,10.2c1.5-1.2,3-2.3,4.6-3.5c0,0.9,0,1.9,0.1,2.8c0.1,7.6,12,7.6,11.8,0c0-1.7-0.1-3.3-0.1-5c1.9,1,3.7,2,5.6,2.9C49.8,47.7,55.8,37.5,49,34z",
      15: "M48.4,16.3c-7.9-3.5-15.7-5.6-24.2-6.7c-0.8-0.1-1.7,0.1-2.5,0.4c-3.9-1.7-9.4,0.2-9.3,5.7c0.1,10.6,0.1,21.3,0.2,31.9c0,3.7,3,6.3,6.5,6.5c8.5,0.5,16.9,0.4,25.4-0.2c3.6-0.3,6.4-2.8,6.5-6.5c0.2-8.5,0.4-17,0.7-25.6C51.7,19.9,50.4,17.2,48.4,16.3z",
      16: "M36.3,32.96c11.1-6.41,9.85-20.75-0.82-23.8c-7.09-2.03-19.75,5.21-20.33,16.89c-0.37,7.49,4.94,12.55,9.02,13.19c-2.28,2.13-1.97,13.82,7.61,13.92c8.11,0.08,10.15-8.2,10.15-12.18C41.92,34.24,37.58,33.21,36.3,32.96z",
      17: "M38.21,23.84c0,1.42,0.96,5.76-2,7.45c-2.43,1.4-10.79,1.61-9.59-4.65c0.37-1.92,1.23-3.9,1.38-4.23c2.9-6.43-6.81-11.27-9.72-4.79c-0.82,1.83-1.59,2.94-2.2,8.06c-1.14,9.53,2.2,21.89,12.35,24.4c6.24,1.54,12.4-1.51,16.16-6.46c4.31-5.67,4.67-12.94,4.68-19.79C49.27,16.73,38.22,16.72,38.21,23.84z",
      18: "M48.7,33.8c-0.5-0.3-1-0.6-1.6-0.8c-2.9-0.7-5.6-2.2-7.5-4.5c0.3-6.4-0.6-13.6-6.5-17.1c-5.8-3.5-13.6-0.3-14.9,6.4c-1.1,6.1,7,9.1,9.8,4.9c0.1,0.2,0.2,0.5,0.3,0.7c0.3,1.4,0.4,2.9,0.3,4.4c0,1.4,0,2.8-0.2,4.2c-0.1,0.8-0.3,3.6-1.2,3.9c-2,0.9-5.3-4-6-5c-3.7-5.3-11-1.9-10,4.2c3.4,21.5,32.1,25.6,42.2,6.8C55.5,38,52.2,34.2,48.7,33.8z",
      19: "M55.38,31.12c-0.75-5.44-5.7-9.23-11.18-8.02c-4.38,0.97-8.34,4.92-11.82,9.01c-1.76-4.79-4.29-9.37-7.83-12.54c-4.66-4.16-11.66-3.5-13.88,2.76c-3.36,9.49,4.61,27.58,17.5,27.91C37.03,50.47,57.14,43.98,55.38,31.12z",
      20: "M59.74,32c0-4.21-3.41-7.62-7.62-7.62h-1.74l1.23-1.23c2.97-2.97,2.97-7.8,0-10.77c-2.97-2.97-7.8-2.97-10.77,0l-1.23,1.23v-1.74c0-4.21-3.41-7.61-7.61-7.61h0c-4.21,0-7.61,3.41-7.61,7.61v1.74l-1.23-1.23c-2.97-2.97-7.8-2.97-10.77,0c-2.97,2.97-2.97,7.8,0,10.77l1.23,1.23h-1.74c-4.21,0-7.62,3.41-7.62,7.62c0,4.21,3.41,7.61,7.62,7.61h1.74l-1.23,1.23c-2.97,2.97-2.97,7.8,0,10.77c2.97,2.97,7.8,2.97,10.77,0l1.23-1.23v1.74c0,4.21,3.41,7.62,7.61,7.62h0c4.21,0,7.61-3.41,7.61-7.62v-1.74l1.23,1.23c2.97,2.97,7.8,2.97,10.77,0c2.97-2.97,2.97-7.8,0-10.77l-1.23-1.23h1.74C56.33,39.61,59.74,36.21,59.74,32z",
      21: "M32.3,15.7c-9.4,0.2-16.9,6.5-16.8,16.7c0.1,8.2,6.9,15.2,15.1,15.5v-5.5c-5.2-0.3-9.5-4.9-9.6-10.2c-0.1-6.7,4.8-10.8,11-10.9c5.8-0.1,9.9,4.8,10,10.4c0.2,6.3-4.9,10.6-10.5,10.7c-0.3,0-0.6,0-0.9,0v5.5c0.3,0,0.6,0,0.9,0c8.6-0.2,16.3-6.8,16-16.3C47.4,23.1,41.2,15.6,32.3,15.7z",
      22: "M29.01,12.84c-11.64,4.08-16.51,15.72-12.23,28.82c2.33,7.13,0.81,13.06,3.64,14.41c6.11,2.91,6.55-3.64,15.72-5.82c9.86-2.35,17.11-17.65,11.7-29.52c-4.13-9.05,0.38-14.44-4.3-16.29C37.77,2.15,36.8,10.12,29.01,12.84z",
      23: "M47.63,19.47c-3.84-4.21-15.21,4.25-15.77,4.68c-0.38-0.37-8.12-9.91-14.27-5.04c-9.42,7.46,7.91,15.39,8.48,15.93c0,0.01-3.59,14.48,4.79,14.63c11.3,0.2,6.91-14.94,6.94-15.73C38.08,33.35,56.27,28.95,47.63,19.47z",
      24: "M27.74,7.23c-9.71-14.56-5.79,33.08-7.58,43.71c-2.31,13.77,21.31-1.96,27.06-4.09C58.83,42.56,40.93,27.02,27.74,7.23z",
      25: "M31.48,16.6c-1.59,0.2-19.87,11.96-20.2,14.58c-0.21,1.68,16.47,13.32,21.25,14.44v-8.81c-2.19-0.93-9.37-4.94-9.37-5.44c0-0.3,8.21-5.91,8.37-5.9c0.34,0,8.89,4.61,9,5.7c0.02,0.23-7.1,5.89-7.8,5.73c-0.05-0.01-0.12-0.04-0.2-0.07v8.8c0.22,0.05,0.43,0.09,0.6,0.09c1.62,0.03,19.1-12.32,19.12-14.2C52.28,29.27,32.94,16.41,31.48,16.6z",
      26: "M60.27,31.26c-0.69-6.09-18.21-8.7-19.27-8.82c-0.27-1.5-3.06-20.47-9.95-20.25c-7.76,0.25-7.45,20-7.54,20.54c-0.1,0-19.36,1.33-19.47,8.47c-0.08,5.34,14.69,8.94,19.2,9.43c0.01,0.08,2.28,19.28,9.42,19.25c6.31-0.02,8.57-18.28,8.63-18.6C44.74,40.66,61.18,39.27,60.27,31.26z",
      27: "M48.12,55.74c10.27,4.94,4.91-20.98,3.36-30.61C49.7,14.07,25.25,12.53,20.7,11.49c-5.16-1.17-12.29,11.75-14.84,17C2.5,35.4,24.48,44.38,48.12,55.74z",
      28: "M39.58,17.39c-0.45-4.4-2.61-8.63-7.91-8.54c-3.76,0.06-6.82,2.8-7.84,9.63c-1.53,10.2-8.09,8.48-7.91,19.47c0.2,12.35,11.86,15.3,16.9,15.03c5.93-0.32,15.32-4.21,14.98-14.41C47.36,24.98,40.53,26.81,39.58,17.39z",
      29: "M51.53,26.51c2.76,0.57,2.21,8.72-0.2,9.33c-7.81,1.97-17.38,1.29-31.29,2.75c-1.04,0.11-8.45,1.94-10.02-1.04c-1.43-2.72-1.52-5.6-1.52-6.93c0-3.04,2.01-3.39,7.41-3.2C21.08,27.6,46.86,25.55,51.53,26.51z",
      30: "M45.1,15.5c-4.3,0.1-12.7,0-15.1,0c-12.4-0.1-14.3,9-14.5,12.7c-0.6,10-0.4,16.2-0.3,17.7c0.4,13,16.1,12.1,16.5,1.5c0.3-6.6-0.8-16.1-0.8-16.3c0.4,0,8.4,1.6,16.6,1.1C59.7,31.5,58.7,15.2,45.1,15.5z",
      31: "M33.97,8.08v16.64h20.5V8.07z M54.48,28.29h-20.5v27.96c11.26-0.02,20.42-9.11,20.51-20.35v-7.62z M30.12,56.28v-16.64H9.6v16.65z M9.61,28.44v7.62h20.5v-27.96c-11.26,0.02-20.42,9.11-20.51,20.35z",
      32: "M54.84,27.29c-2.59-11.05-11.68-13.61-19.86-13.24c-0.5,0.02-1.08-8.84-6.34-8.55c-4.96,0.28-4.47,9.53-4.41,9.82c-0.67,0.02-17.38,5.21-15.17,20.24c2.07,14.07,17.66,15.63,19.6,15.61c0.09,0,2.19,8.66,7.01,8.11c5.37-0.61,4.9-9.87,4.96-10.34C47.53,47.01,57.87,40.26,54.84,27.29z",
      33: "M50.27,20.08c-6.29,0.43-8.4,6.33-8.4,6.33c-1.71-4.02-6.7-6.62-10.73-5.85c-7.11,1.35-8.15,7.5-8.16,7.52c-0.27-0.1-3.77-6.41-10.83-5.21c-7.99,1.37-9.21,8.4-8.55,11.92c1.25,6.67,8.48,8.48,11.07,8.21c5.51-0.57,9-5.81,9-5.81s3.12,4.51,8.59,4.26c8.42-0.38,9.04-6.38,9.82-6.24c0,0,4.05,6.41,12.23,4.15c4.86-1.34,7.46-7.05,7.11-10.19C60.58,21.33,53.57,19.86,50.27,20.08z",
      34: "M44,53.6c3.4-1.9,0.7-8,0.7-8c7.2-4.2,11.2-18.7,1.3-27.1c0,0,3-5.6-0.8-7.9c-3.7-2.3-6.7,2.6-6.7,2.6c-3.3-1.1-7.8-1.3-12.4,0.2c0,0-2.2-5.3-6.1-4c-4.2,1.3-4.1,5.5-2.2,8.8c-8.4,6-8.6,22.6,1.4,28.5c0,0-2.7,5.3,0.6,7.4c5.2,3.3,7.7-3.6,7.7-3.6c2.7,0.5,5.7,0.5,8.9-0.4C36.3,50.1,37.9,56.9,44,53.6z",
      35: "M38.67,24.14c0,0,0.25-13.15-0.87-14.15c-2.1-1.87-27.36-2.68-28.3,0.62c-1.66,5.85-1.43,26.18-0.25,27.18c1.62,1.37,14.58,1.18,14.58,1.18s-1.06,12.11,0,13.96c0.75,1.31,27.92,3.74,29.42,0.31c0.99-2.28,2.87-24.74-0.37-27.61C51.63,24.52,38.67,24.14,38.67,24.14z",
      36: "M47.81,23.96C44.8,18.83,35.78,8.99,25.06,12.24c-10.73,3.24-11.49,14.71-10.87,20.1c1.64,14.21,12.31,19.8,19.73,19.48C53.78,50.95,52.7,32.27,47.81,23.96z",
      37: "M29,7.8c-7.4,0.7-3.5,11.2-7,13.9c-4.4,3.2-13.6,0-14.8,5.9c-1.3,6.8,8.1,5.3,10.6,9.9c3.3,6.3-3.4,12.4,2,15.4c5.5,3.1,9-6,13.7-7.3c5.5-1.5,9.7,8.4,14.8,4c5.2-4.5-3-11.1-1.3-15.9s9.9-7.3,7.4-11.9c-3-5.6-11.4,0.9-15.9-1.9S35.8,7.1,29,7.8z",
      38: "M35.8,17.1c-4.5-1.2-4.8-6.6-9.4-5.4s-2.1,6-5.4,9.4s-8.1,0.9-9.4,5.4s4.2,4.8,5.4,9.4s-3.3,7.5,0,10.8c3.3,3.3,6.3-1.2,10.8,0c4.5,1.2,4.8,6.6,9.4,5.4s2.1-6,5.4-9.4c3.3-3.3,8.1-0.9,9.4-5.4c1.2-4.5-4.2-4.8-5.4-9.4s3.3-7.5,0-10.8C43.4,13.9,40.3,18.3,35.8,17.1z",
      39: "M48.5,31.8c-0.2-12-8.1-16.5-16.8-16.3c-7,0.2-17.1,4.5-16.8,16.3c0.3,12.9,12.9,13.9,13,15.9c0.1,1.4-6.3,2.3-6.4,4.6c-0.1,4.7,22.4,5.1,22.3-0.8c0-2.6-6.6-2.8-6.7-4.1C36.9,45.1,48.7,44.2,48.5,31.8z",
      40: "M31.2,12.1c-4.9,0.2-6.9,3.7-5.8,7.7c0,0-3.4-5.1-8.1-1.9c-3.4,2.3-3.4,6-1.8,8.4c0,0-9-1.9-9.3,5.4c-0.2,6.2,6.4,7.3,9.9,5.1c0,0-4,4.8,0,8.3s7.8,0.7,9.3-0.9c0,0-1.8,8.4,5.7,8.1c6.8-0.3,7-5.7,6.5-8.1c0,0,4.2,4.8,8.4,2s2.9-6.3,2-8.7c0,0,10,2.5,9.9-4.9c-0.1-7.4-6.8-7.7-9.8-5.6c0,0,3.7-5.3-0.9-8.9c-4.2-3.3-8,0.2-9.4,1.7C37.8,20,39,11.8,31.2,12.1z",
      41: "M55.9,25.8c0.4-1.6-1.4-3.6-3.6-3.1c-4.7,1-11.1,1.1-15.9-3.7c-2.3-2.3-5.6-2.1-8.1,0c-4.4,3.8-9.3,4.5-16.3,3.6c-2.8-0.4-4.4,1.1-3.8,3.5C11.5,39.1,23.6,49,32.7,48.6C44.3,48.2,54.6,31.6,55.9,25.8z",
      42: "M44.1,19.4c-2.8-0.5-6.3-1.8-8.9-5.3c-1.8-2.4-4.9-1.8-6.3,0.1c-2.6,3.6-6.5,4.1-8.7,4.4c-3.4,0.5-4,3.4-4,5.4c0.2,19.8,12,28.6,15.1,28.5c3.6-0.1,17-10.8,16.4-28.9C47.6,22,47,19.9,44.1,19.4z",
      43: "M46.5,21.1c-1.5,0-28.3,0.1-31.3,0.2c-4.4,0.1-5.6,1.2-5.7,7.5c0,1.4-0.1,7.6-0.1,9.3c0.1,3.1,1.2,4.4,4.4,4.4c1.6,0,31.4-0.1,32-0.1c2.5-0.1,10.1-1.7,9.8-9.2C55.5,30.8,55.6,21.2,46.5,21.1z",
      44: "M53.9,33c2.3-9.8-11.6-15-17-8.9c0.6-0.7,4.1-11.6-5.6-15.4c-3.7-1.5-15.9,1.3-13.8,13.3c0.9,5.1,4.7,6.6,6.4,6.8c0,0,0,0-0.1,0c-5.1,0.1-10.5,9-7.2,15.6c2.1,4.2,8.4,8.3,15.4,5.4c6.8-2.7,6.1-7.8,6.5-10.3C38.5,39.2,51.1,44.8,53.9,33z",
      45: "M42.1,32.1c4-6.4-0.3-12.1-2.2-13.7c-5.7-4.8-16.8-2.9-20,0.2c-5.3,5.1-2,12.5,2.5,12.8c-0.4,0.2-7.4,5.1-3.2,12c2,3.2,8.7,4,12.2,0.9c1.4,1.6,7.9,3,11.3,0.1C45.2,42.3,46.8,35.7,42.1,32.1z",
      46: "M38.6,24.8c-5.4-4.4-2.9-19.6-6.2-19.6c-3.5,0-2.5,15.2-6.2,19.1c-5.3,5.5-18.9,4-19,6.6c-0.1,2.9,13.6,3,18,7.3c4.8,4.7,4.7,20.8,7.2,20.8c3.4,0,2.6-16.6,6.9-21.4c4.5-5.2,18-3.5,18.2-6.4C57.6,27.6,43.9,29.2,38.6,24.8z",
      47: "M54.8,38.8c-1.5-7-7.8-7.8-12.5-7.8c2.1-0.9,10.5-3.8,8.7-12.7C49.3,9.8,39.1,7.9,29,9.5C17.4,11.2,8.6,18.6,9.8,25.5c1.4,8.1,8.8,8.2,10.8,8.6c-2.2,1.1-9.9,4.6-7.3,14.2c1.3,5,9.2,9.5,22.5,7.5C43.4,54.7,57.3,50.7,54.8,38.8z",
      48: "M25.1,53C36.5,40.8,36.5,37.2,47,37.1c10.3-0.1,10.6-10.4-0.7-10.3c-9.9,0.1-11.1-2-21.5-14.6C7.8-8.4,8.3,71,25.1,53z",
      49: "M60.8,35.1c-0.1-7-5.6-14.1-13.9-14.2c-3.3,0-9.3,1.2-11.4,5.1c-0.9-7.2-5.3-13.6-13.7-13.5C4.8,12.5,5.8,31.7,5.8,38c0,9,5.7,13.9,12.2,14.1c10.2,0.3,10.8-8.8,11.3-10.4c0.8,1.5-0.6,8.4,7.8,9.3c6.2,0.7,8.8-3.9,8.8-9.7c0.5,0.8,1.8,9.3,8.7,7.3C60.5,46.9,60.8,38.4,60.8,35.1z",
      50: "M49.8,37.4c-1.9-2.1-9.9-13.5-12.4-17.2c-6.6-9.6-8.4-7-13.1,3.1c-3.2,6.9-6.7,15.4-8.7,19.4c-3,6,1.8,6.5,8,5.4c8.9-1.6,22.1-3.1,25-3.3C54,44.3,53,40.8,49.8,37.4z",
      51: "M39.9,19.3c-1-27.8-16.3-10.8-16.3,16.2c0,17.8,11.9,38.6,15.2,11.2C39.8,38,40.3,30.2,39.9,19.3z",
      52: "M58.1,29.5C56.7,27.7,45,16.7,30.7,17C16.2,17.4,7.2,28.3,6,30.2c-2.1,3.2-0.2,5.2,0,5.6c1.1,1.9,11,12.4,24.6,12.7c19.7,0.4,27.9-12.9,28.5-13.7C60.4,33,59.1,31,58.1,29.5z",
      53: "M52.1,29.3c1-8.6-4.6-21.3-20-21.4c-13.4,0-23.5,10.7-20.8,21.9c3.3,13.6,15.7,26,16.8,26.9c4,3.6,5.1,2.9,8.4-1C39,52.9,50.9,40.2,52.1,29.3z",
      54: "M46.5,27c1.2-5.7,8.5-14.6,9.5-15.7c3.4-3.8-0.6-7.4-4.1-3.9c-1.5,1.4-5.8,5.7-14.8,8.7c-3.7,1.2-9.2-2-16.9,5.5c-5.2,5-5.8,14.2,0.6,21c4.9,5.3,15,5.4,20.2,0.2C48.2,35.2,45.9,30.1,46.5,27z",
      55: "M51.8,16.9c-1.2-1.5-3-0.4-4.3,0.6c-2.4,1.8-3.8,7-4.1,8c-0.6-3.3-2.3-9.2-4.2-11.6c-1-1.2-2.3-2.6-4.1,0.8c-1.4,2.7-2.5,9.1-2.9,10.6c-0.4-1.6-2.6-9.7-5.3-10.9c-3-1.3-3.9,2-4.5,4.2c-0.5,1.7-1.4,6-1.5,6.4c-0.1-0.3-3.4-10-7.3-12.1c-0.7-0.4-1.4,0-1.8,0.7c-3.2,6.8-2.7,27.7,2.6,32.1c6.1,5.2,28.8,6.8,34.5,1.7C54.7,42.2,56.6,22.9,51.8,16.9z",
      56: "M55,10.9c-0.9-0.7-6.2-6.8-21.3-4.4C15,9.5,18.3,37,16.9,43.1c-1.2,5.1-8.1,4.4-8.7,4.4c-1.9,0.1-1.5,2-0.7,2.6c0.9,0.7,6.1,6.1,21.3,4.4c22.5-2.5,15.4-30.5,16.8-36.6c1.2-5.1,8.1-4.4,8.7-4.4C56.3,13.4,55.9,11.5,55,10.9z",
      57: "M35.8,4.8C31,4.1,25.2,7.4,24.4,15.2c-0.6,6,4.5,9.1,3.6,15.2c-0.9,5.9-6.8,6.8-8.8,17.8c-1,5.6,2.4,11.8,8.5,12.2c7.2,0.5,10.7-4.3,11.8-12.1c1.1-8-4.6-9-4-16.5c0.6-6.2,5.8-6.7,7.1-13.7C43.9,11.1,40.6,5.5,35.8,4.8z",
      58: "M63.6,31.3c-0.5-2.2-2.8-3.4-4.7-4.1c-2.8-1-13.6,0-20.7-0.2c-16-0.6-30.6-3.7-32-3.8C1.8,23,1,26.8,0.7,28.3c-1.1,6,6.5,8.1,8.4,8.3c3,0.4,17.4,2.6,24.2,2.9c10.4,0.4,25.2,0.2,28-1.8C63.2,36.3,64.1,33.7,63.6,31.3z",
      59: "M62.6,31.1c-0.1-0.5-0.7-1.1-1.2-1.2c-2.6-0.4-40.2-0.3-43.5-1.6c-0.2-4.1-4.5-6-8.8-5.9c-5.2,0.1-8.3,4.1-8.3,9.5s3.5,8.9,8.8,8.9c3.4,0,7.4-2.2,8.5-5.5c14.7,0.5,35.7,2.7,42.9,1.9c0.5-0.1,1.2-0.7,1.2-1.2C62.4,34.2,62.9,32.8,62.6,31.1z",
      60: "M44.9,16.6c-0.2-0.8-0.6-1.3-1.7-2.2c-0.4-0.3-12.7-9.6-13.9-9.5C27,5,17.5,17.6,16.9,19.7c-0.5,1.9,2,24.4,2.1,24.9c0.3,2,1.2,2.2,2.4,3.5c1.9,2,11.9,9.5,13.5,9.8c0.6,0.1,1-0.3,1.4-0.8c0.6-0.9,9.2-10.6,10-12c0.4-0.6,0.6-1.3,0.7-2.1C47.4,41.1,45.7,20.7,44.9,16.6z",
      61: "M37.5,15.6c2.5-1.8,2.9-9.3-4.5-10.6C22.1,3.3,23.3,15.2,26.3,16c-2.4,0.4-11.7,3.8-11.7,16.2c0,11.6,9,16.4,10.1,17c-3.4,4.5,0.2,10.5,6.9,10.1c8.4-0.5,8.6-7.7,7.2-10.1c8.9-3.1,10-15.2,10.1-17.1C49.1,17.7,39.2,15.7,37.5,15.6z",
      62: "M47.2,21.2c0-2.1,0.4-4.3-1.9-5.4c-0.8-0.4-24.7-0.5-27.4,0.2c-1.1,0.3-1.6,1.2-1.6,2.4c-0.2,0.5-0.2,26.4,0,27.9c0.1,0.4,0.5,0.7,0.8,0.8c2.3,0.5,26.7,0.2,28.9-0.3c0.4-0.1,0.8-0.5,0.9-0.9C47.6,43.5,47.2,24.2,47.2,21.2z",
      63: "M46.3,29.1c-1.7-2.1,2.5-9.4-1.9-15c-0.7-13.6-9.3-8.1-17.2,3c-1.2,0.7-3.4,2.5-5.3,5.8c-0.1-0.1-11.9,14.5-6.8,20.5c-0.6,0.2,23.3,19.2,28.7,0C45.9,41.9,47.6,39.2,46.3,29.1z",
      64: "M48.1,22.3C45.5,16.1,39.7,3,28.6,6.9c0,0-0.8,0.3-0.4,0.2c-5,1.5-17.5,14.4-12.9,20c0.1,2.2,0.5,3.6,9.8,2.8c2.5,7.1-6.3,19.5,3,26.2c15.8,3.7,8.3-13,10.2-26.2C41.1,29.8,52.9,30.4,48.1,22.3z",
      65: "M51,23.9c-4.6-13.7-15.6-9.9-14,6.5c-2.2,0.9-1.1,1.3-8,0.6c3.2-7.6,2.1-26.3-12.3-21.4C-13.9,31.6,49.7,89.5,51,23.9z",
      66: "M21.7,11.2c-3.4,2.6-5.2,11.7,3.1,12.3c-3.4,0.8-16.3,11.4,0.4,15.3C5.8,47,27.8,59.9,43.8,53.9c13.1-2.5,19.1-20.5-4.5-17.2c2.5-1,10.9-5.6,5.5-10.2c-1.8-2.4-3.4-4.1-11.9-4.6c3.1-0.7,7.9-10.4,0-12C31.2,9.3,28.1,6.8,21.7,11.2z",
      // : "",
    };
  }
}