/* global TWEEN, THREE */

// シーケンスデータをセットする
export function setSequence(data, func = null) {
  return new Promise((resolve) => {
    let seqlist = [];
    data[0].parameters.forEach((seq) => {
      seqlist.push(playSequence(data[0].duration, seq));
    });
    Promise.all(seqlist).then(() => {
      if (typeof data[0].func === "function") data[0].func();
      data.shift();
      if (data.length > 0) {
        setSequence(data, func)
          .then(resolve);
      } else {
        if (typeof func === "function") func();
        resolve();
      }
      // 次のアニメーションがあったら setSequenceNew
    });
  });
}

// シーケンスデータを再生する
// デフォルト設定と設定の省略プロセスを追加する
function playSequence(time, data) {
  return new Promise((resolve) => {
    const set = data[1].split(".");
    if (set === "") resolve();
    //
    const el = data[0];
    if (!data[3]) data[3] = "Linear.None";
    const easeSet = data[3].split(".");
    const coords = {
      "value": el[set[0]][set[1]]
    };
    const to = {
      "value": data[2]
    };
    const ease = TWEEN.Easing[easeSet[0]][easeSet[1]];
    //
    new TWEEN.Tween(coords)
      .to(to, time)
      .easing(ease)
      .onUpdate(() => {
        el[set[0]][set[1]] = coords.value;
      })
      .onComplete(() => resolve())
      .start();
  });
}

function svgToShapeSet(path) {
  let value = "";
  let set = [];
  path.split("").forEach((word) => {
    if (isNaN(word)) {
      if (word === ".") return value += word;
      if (word === ",") return value += " ";
      if (word === "-") return value += " " + word;
      if (value) set.push(value.split(" ").filter(q => q !== ""));
      value = word + " ";
    } else {
      value += word;
    }
  });
  set.push(value.split(" ").filter(q => q !== ""));
  return set;
}

export function svgResize(path, mag = 1) {
  const arr = svgToShapeSet(path);
  let newPath = [];
  let pos = 0;
  if (mag > 1) pos = Math.floor(-640 * (mag / 4)) / 10;
  if (mag < 1) pos = Math.floor((640 - (640 * mag))/2) / 10;
  arr.forEach((line) => {
    line.forEach((value,i)=>{
      if (i === 0){
        newPath.push(value);
      } else {
        if (!line[0].match(/^[a-z]+$/)){
          const num = (Number(value) * mag);
          const floor = Math.floor(num * 10) / 10;
          newPath.push(String(floor + pos));  
        } else {
          const num = Number(value) * mag;
          const floor = Math.floor(num * 10) / 10;
          newPath.push(String(floor));
        }
      }
    });
  });
  return newPath.join(",");
}

export function svgToShape(path, x = 0, y = 0, mag = 1) {
  const setNum = (arr) => {
    const r = [];
    const vx = (!arr[0].match(/^[A-Z]+$/)) ? Number(cX) : Number(0);
    const vy = (!arr[0].match(/^[A-Z]+$/)) ? Number(cY) : Number(0);
    // 小文字の場合は相対座標
    arr.forEach((num, i) => {
      if (i === 0) return r.push(num);
      const n = Number(num) * mag;
      switch (arr[0]) {
      case "H":
      case "h":
        r.push(Math.floor((Number(vx) + n) * 100) / 100);
        break;
      case "V":
      case "v":
        r.push(Math.floor((Number(vy) - n) * 100) / 100);
        break;
      default:
        if (i % 2 === 1) r.push(Math.floor((Number(vx) + n) * 100) / 100);
        if (i % 2 === 0) r.push(Math.floor((Number(vy) - n) * 100) / 100);
        break;
      }
    });
    return r;
  };
  //
  const set = svgToShapeSet(path);
  const shape = new THREE.Shape();
  let fX = 0;
  let fY = 0;
  let cX = 0;
  let cY = 0;
  let p = [];
  set.forEach((d) => {
    switch (d[0]) {
    case "M":
    case "m":
      p = setNum(d);
      fX = cX = Number(p[1]);
      fY = cY = Number(p[2]);
      shape.moveTo(p[1] + x, p[2] + y);
      break;
    case "C":
    case "S":
      p = setNum(d);
      if (d[0] === "S") p = [p[0], cX, cY, p[1], p[2], p[3], p[4]];
      cX = Number(p[5]);
      cY = Number(p[6]);
      shape.bezierCurveTo(p[1] + x, p[2] + y, p[3] + x, p[4] + y, p[5] + x, p[6] + y);
      break;
    case "c":
    case "s":
      p = setNum(d);
      if (d[0] === "s") p = [p[0], cX, cY, p[1], p[2], p[3], p[4]];
      cX = Number(p[5]);
      cY = Number(p[6]);
      shape.bezierCurveTo(p[1] + x, p[2] + y, p[3] + x, p[4] + y, p[5] + x, p[6] + y);
      break;
    case "L":
    case "l":
      p = setNum(d);
      cX = Number(p[1]);
      cY = Number(p[2]);
      shape.lineTo(p[1] + x, p[2] + y);
      break;
    case "H":
    case "h":
      p = setNum(d);
      cX = Number(p[1]);
      shape.lineTo(cX + x, cY + y);
      break;
    case "V":
    case "v":
      p = setNum(d);
      cY = Number(p[1]);
      shape.lineTo(cX + x, cY + y);
      break;
    case "Z":
    case "z":
      if (cX !== fX || cY !== fY) shape.lineTo(fX + x, fY + y);
      cX = fX;
      cY = fY;
      break;
    }
  });
  return shape;
}

