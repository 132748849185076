import Ball from './ball'; //eslint-disable-line
import {
  setSequence
} from './common'; //eslint-disable-line

/* global THREE */
export default class Anago {
  constructor(root) {
    this._root = root;
    //
    this.setting = root.getRandomActor();
    this.count = 0;
    this.step = 1;
    //
    this.isActionComplate = true;
    this.isHide = false;
    //
    this.objects = {};
    //
    // 本体生成
    this._initBody();
    this.resetCharacterPos();
    this.resetCharacterPhysique();
    //
    this._initCharm();
    this._initBall();
    //
  }

  resetCharacter() {
    this.setting = this._root.getRandomActor(this.setting);
    const material = this._root.materials.getColorFromId(this.setting.color);
    //
    this.objects.g.remove(this.objects.eyes);
    this.objects.eyes = this._root.geometrys.getEyesSetFromId(this.setting.eyes);
    this.objects.g.add(this.objects.eyes);
    //
    this.objects.hand.geometry = this._root.geometrys.getHandFromId(this.setting.hand);
    this.objects.body.material = material;
    this.objects.head.material = material;
    this.objects.hand.material = material;
    //
    this.resetCharacterPos();
    this.resetCharacterPhysique();
    //
    this._initCharm();
    this._initBall();
  }

  resetCharacterPos() {
    this.objects.g.position.set(this.setting.x, -30, this.setting.z);
    this.objects.eyes.position.set(0, this.setting.center, 0.1);
    //
    this.objects.hand.position.set(0, 10, 0);
    this.objects.ball.position.set(1, 17, 0);
    this.objects.joint.position.set(this.setting.shoulder, 10, -0.1);
    this.objects.joint.rotation.set(0, 0, 0);
    //
    if (this.objects.charm) this.objects.charm.position.set(0, this.setting.center, 0.15);
  }

  resetCharacterPhysique() {
    const width = this.setting.width;
    const size = Math.floor(width * -4) / 10;
    //
    let offset = 0;
    if (width > 1) offset = -(size - 1);
    if (width < 1) offset = -(1 - size);
    //
    this.objects.head.position.set(size, 20 - offset, 0);
    this.objects.body.position.set(size, -offset, 0);
    this.objects.head.scale.set(width, width, 1);
    this.objects.body.scale.set(width, 1, 1);
    this.objects.g.scale.set(1 * this.setting.dominant, 1, 1);
  }

  update() {
    this.count++;
    if (this.count % this.setting.blink === 1) this.animationBlink();
    //
    if (this.step % 2 === 0) {
      if (this.isHide === true) return;
      if (this.isActionComplate === false) return;
      if (this._root.isActorActive(this.setting.id) == false) return;
      if (this.setting.wait[Math.floor(this.step) / 2] < this.count) this.step++;
    } else {
      this.isActionComplate = false;
      if (this.step === 1) this.animationIn();
      if (this.step === 3) this.animationThrow();
      if (this.step === 5) this.animationOut();
      if (this.step === 7) {
        this.resetCharacter();
        this.isActionComplate = true;
        this.step = 0;
      }
      this.count = 0;
      this.step++;
    }
  }

  setHide(value) {
    if (value === this.isHide) return;
    this.isHide = value;
    if (this.isHide === true) {
      setSequence([{
        duration: 800,
        parameters: [
          [this.objects.g, "position.y", -50, "Quadratic.Out"]
        ]
      }]);
    }
  }

  _initBody() {
    this.objects.g = new THREE.Group();
    const material = this._root.materials.getColorFromId(this.setting.color);
    //
    // 胴体
    const geometry1 = new THREE.PlaneGeometry(19, 42, 1, 1); // 身体
    this.objects.body = new THREE.Mesh(geometry1, material);
    this.objects.g.add(this.objects.body);
    const geometry2 = this._root.geometrys.head; // 頭
    this.objects.head = new THREE.Mesh(geometry2, material);
    this.objects.g.add(this.objects.head);
    //
    // 目
    this.objects.eyes = this._root.geometrys.getEyesSetFromId(this.setting.eyes);
    this.objects.g.add(this.objects.eyes);
    //
    // 手
    this.objects.joint = new THREE.Group();
    this.objects.ball = new THREE.Group(); // 手のボール位置
    const geometry3 = this._root.geometrys.getHandFromId(this.setting.hand);
    this.objects.hand = new THREE.Mesh(geometry3, material);
    this.objects.joint.add(this.objects.hand);
    this.objects.joint.add(this.objects.ball);
    this.objects.g.add(this.objects.joint);
    //
    this._root.getScene().add(this.objects.g);
  }

  _initBall() {
    this.ball = this._root.createBall({
      color: this.setting.color,
      hold: {
        active: true,
        target1: this.objects.ball,
        target2: this.objects.joint
      },
      type: 4
    });
  }

  _initCharm() {
    if (this.objects.charm) this.objects.g.remove(this.objects.charm);
    //
    const charm = this._root.geometrys.getCharmSetFromId(this.setting.charm);
    if (charm.geometry === null) return;
    const material = this._root.materials.getCharmFromId(charm.material);
    this.objects.charm = new THREE.Mesh(charm.geometry, material);
    this.objects.g.add(this.objects.charm);
  }

  animationIn() {
    setSequence([{
      duration: 500,
      parameters: [
        [this.objects.g, "position.y", this.setting.height, "Quadratic.Out"],
        [this.objects.hand, "scale.x", this.setting.width],
        [this.objects.hand, "scale.y", this.setting.width],
      ]
    }]).then(() => this.isActionComplate = true);
  }

  animationOut() {
    setSequence([{
      duration: 700,
      parameters: [
        [this.objects.g, "position.y", -50, "Quadratic.Out"]
      ]
    }]).then(() => this.isActionComplate = true);
  }

  animationBlink() {
    setSequence([{
      duration: 50,
      parameters: [
        [this.objects.eyes, "scale.y", 0.2, "Quadratic.Out"],
      ]
    }, {
      duration: 50,
      parameters: [
        [this.objects.eyes, "scale.y", 1, "Quadratic.Out"],
      ]
    }]);
  }

  animationThrow() {
    const q = this.setting.hand;
    if (q === 0) this.animationThrowBothHands();
    if (q === 1) this.animationThrowSingleHand();
    if (q === 2) this.animationThrowHeading();
  }

  // 両手投げアニメーション設定
  animationThrowBothHands() {
    setSequence([{
      duration: 0, // ボール位置移動
      parameters: [
        [this.objects.joint, "rotation.z", 0],
        [this.objects.joint, "position.x", this.setting.shoulder],
        [this.objects.ball, "position.x", 0],
        [this.objects.hand, "scale.x", this.setting.width],
      ]
    }, {
      duration: 500, // 手を伸ばす
      parameters: [
        [this.objects.joint, "position.y", 18, "Quadratic.Out"],
      ],
      func: () => {
        this.ball.objects.g.visible = true;
      }
    }, {
      duration: 1000, // ボール出現
      parameters: [
        [this.ball.objects.g, "scale.x", 1 * this.setting.dominant, "Quadratic.InOut"],
        [this.ball.objects.g, "scale.y", 1, "Quadratic.InOut"],
        [this.ball.objects.g, "rotation.z", -2, "Quadratic.InOut"],
      ]
    }, {
      duration: 700, // タメ
      parameters: [
        [this.objects.g, "scale.x", 1.34 * this.setting.dominant, "Quadratic.Out"],
        [this.objects.g, "scale.y", 0.7, "Quadratic.Out"]
      ]
    }, {
      duration: 200, // 伸び
      parameters: [
        [this.objects.g, "scale.x", 0.7 * this.setting.dominant, "Quadratic.In"],
        [this.objects.g, "scale.y", 1.2, "Quadratic.In"]
      ],
      func: () => this.ball.release(1.58, 6, -0.3)
    }, {
      duration: 1000, // 元に戻る
      parameters: [
        [this.objects.g, "scale.x", 1.0 * this.setting.dominant, "Elastic.Out"],
        [this.objects.g, "scale.y", 1.0, "Elastic.Out"]
      ]
    }, {
      duration: 500,
      parameters: [
        [this.objects.joint, "position.y", 10, "Quadratic.Out"]
      ]
    }]).then(() => this.isActionComplate = true);
    return;
  }

  animationThrowSingleHand() {
    //
    setSequence([{
      duration: 0, // 腕の角度設定
      parameters: [
        [this.objects.joint, "rotation.z", 1],
        [this.objects.joint, "position.x", this.setting.shoulder + 5],
        [this.ball.objects.g, "rotation.z", 1],
      ]
    }, {
      duration: 500, // 腕を伸ばす
      parameters: [
        [this.objects.joint, "position.x", this.setting.shoulder - 2, "Quadratic.Out"],
        [this.objects.joint, "position.y", 19, "Quadratic.Out"]
      ],
      func: () => {
        this.ball.objects.g.visible = true;
      }
    }, {
      duration: 300, // 腕を下げる
      parameters: [
        [this.ball.objects.g, "scale.x", 1 * this.setting.dominant, "Quadratic.InOut"],
        [this.ball.objects.g, "scale.y", 1, "Quadratic.InOut"]
      ],
    }, {
      duration: 1000, // 腕を下げる
      parameters: [
        [this.objects.joint, "rotation.z", 2.2, "Quadratic.InOut"],
        [this.ball.objects.g, "rotation.z", 2.2, "Quadratic.InOut"],
        [this.objects.joint, "position.x", this.setting.shoulder - 3, "Quadratic.InOut"],
        [this.objects.joint, "position.y", 24, "Quadratic.InOut"]
      ],
      func: () => {
        this.ball.release(1.4, 6, 0.3);
      }
    }, {
      duration: 200, // 投げる
      parameters: [
        [this.objects.joint, "rotation.z", -0.2, "Quadratic.InOut"],
        [this.objects.joint, "position.x", this.setting.shoulder - 8, "Quadratic.InOut"],
        [this.objects.joint, "position.y", 20, "Quadratic.InOut"]
      ]
    }, {
      duration: 1000, // 余韻
      parameters: [
        [this.objects.joint, "rotation.z", 0.2, "Quadratic.InOut"],
        [this.objects.joint, "position.x", this.setting.shoulder - 7, "Quadratic.InOut"],
        [this.objects.joint, "position.y", 16, "Quadratic.InOut"]
      ]
    }, {
      duration: 500, // 腕を下ろす
      parameters: [
        [this.objects.joint, "rotation.z", 2.6, "Quartic.In"],
        [this.objects.joint, "position.x", this.setting.shoulder - 5, "Quartic.InOut"],
        [this.objects.joint, "position.y", 23, "Quartic.In"]
      ]
    }, {
      duration: 800, // 腕をしまう
      parameters: [
        [this.objects.joint, "rotation.z", 3.2, "Quintic.Out"],
        [this.objects.joint, "position.x", this.setting.shoulder - 6, "Circular.Out"],
        [this.objects.joint, "position.y", 26, "Quartic.Out"]
      ]
    }]).then(() => this.isActionComplate = true);
    return;
  }

  animationThrowHeading() {
    setSequence([{
      duration: 0, // 腕の角度設定
      parameters: [
        [this.ball.objects.g, "scale.x", 1 * this.setting.dominant],
        [this.ball.objects.g, "scale.y", 1],
        [this.objects.ball, "position.x", 0],
        [this.objects.ball, "position.y", 15],
      ],
      func: () => {
        this.ball.objects.g.visible = true;
      }
    }, {
      duration: 600,
      parameters: [
        [this.objects.ball, "position.y", 50, "Sinusoidal.Out"],
        [this.ball.objects.g, "rotation.z", 10, "Linear.None"]
      ]
    }, {
      duration: 600,
      parameters: [
        [this.objects.g, "position.y", -24, "Quadratic.InOut"],
        [this.objects.g, "scale.x", 1.1 * this.setting.dominant, "Quadratic.In"],
        [this.objects.ball, "position.y", 23, "Circular.In"],
        [this.ball.objects.g, "rotation.z", 20, "Linear.None"]
      ],
      func: () => {
        this.ball.release(1.58, 8, -0.9);
      }
    }, {
      duration: 1000,
      parameters: [
        [this.objects.g, "position.y", this.setting.height - 4, "Elastic.Out"],
        [this.objects.g, "scale.x", 1 * this.setting.dominant, "Elastic.Out"]
      ]
    }]).then(() => this.isActionComplate = true);
    return;
  }
}