/* global THREE */

export default class Materials {
  constructor() {
    //
    // 色の設定
    this.colors = {
      setting: [
        [0x3f3fe0, 1],
        [0x8c6239, 1],
        [0x9e005d, 1],
        [0xf24097, 1],
        [0x4b9bea, 1],
        [0xed1c24, 1],
        [0xf47b2a, 1],
        [0x56efa2, 1],
        [0x8eea53, 1],
        [0x2de4f7, 1],
        [0xedcc2e, 1]
      ],
      stock: [],
      data: []
    };
    this.colors.data = this._initColors();
    //
    // チェームポイントの色
    this.charms = {
      setting: [
        [0x000000, 1]
      ],
      data: []
    };
    this.charms.data = this._initCharms();
  }

  // ボデイーカラー設定
  _initColors() {
    const materials = [];
    this.colors.setting.forEach((color) => {
      materials.push(
        new THREE.MeshBasicMaterial({
          side: THREE.FrontSide,
          color: color[0]
        })
      );
    });
    return materials;
  }

  getRandomColorId() {
    if (this.colors.stock.length === 0) {
      this.colors.setting.forEach((color, num) => {
        for (let i = 0; i < color[1]; i++) {
          this.colors.stock.push(num);
        }
      });
    }
    const num = Math.floor(Math.random() * this.colors.stock.length);
    const id = this.colors.stock[num];
    this.colors.stock.splice(num, 1);
    return id;
  }

  getColorFromId(id) {
    return this.colors.data[id];
  }

  // チャームポイントカラー設定
  _initCharms() {
    const materials = [];
    this.charms.setting.forEach((color) => {
      materials.push(
        new THREE.MeshBasicMaterial({
          side: THREE.FrontSide,
          color: color[0]
        })
      );
    });
    return materials;
  }
  
  getCharmFromId(id) {
    return this.charms.data[id];
  }

  _initRoughMaterial() { // ラフエフェクト試作
    const fragment = `
      varying vec3 vUv;
      void main() {
        float a = 1.0;
        gl_FragColor = vec4(0.0, 0.0, 1.0, a);
      }`;
    const vertex = `
      // attribute float vertexDisplacement;
      varying float vOpacity;
      varying vec3 vUv;
      void main() {
        vUv = position;
        vec3 transformed = vec3(position);
        transformed.x = transformed.x + sin(transformed.y*15.5)*0.2;
        transformed.y = transformed.y + sin(transformed.x*15.5)*0.2;
        vec4 modelViewPosition = modelViewMatrix * vec4(transformed, 1.0);
        gl_Position = projectionMatrix * modelViewPosition;
      }`;
    const uniform = {
      delta: {
        value: 0
      }
    };
    return new THREE.ShaderMaterial({
      transparent: true,
      uniform: uniform,
      vertexShader: vertex,
      fragmentShader: fragment
    });
  }
}