import {
  setSequence
} from './common'; //eslint-disable-line
/* global THREE */

export default class Ball {
  constructor(root, setting) {
    this._root = root;
    this._scene = setting.scene;
    //
    this.count = 0;
    this.status = {
      x: setting.x,
      y: setting.y,
      z: setting.z,
      visible: setting.visible,
      color: setting.color,
      orbit: {
        direction: 0,
        force: 0,
        destination: setting.destination,
        twist: Math.floor((Math.random() * 4 + 1) * 5) / 100,
        twistMin: (Math.floor(Math.random() * 10) + 10) / 100
      },
      size: setting.size
    };
    //
    this.objects = {
      geometry: this._root.plotBoard.getRandomPlotGeometry(),
      material: this._root.materials.getColorFromId(this.status.color)
    };
    //
    this.hold = setting.hold;
    this._initBody();
  }

  update() {
    if (this.hold.active === true) {
      const pos = this.hold.target1.getWorldPosition(new THREE.Vector3);
      this.objects.g.position.set(pos.x, pos.y, pos.z - Number(0.1));
    } else {
      this._moveToOrbit();
      this.count++;
    }
  }

  _moveToOrbit() {
    const va = (this.count / 100 > 1) ? 1 : this.count / 100;
    const vboz = this._root.status.boardOffsetZ;
    const vbox = this._root.status.boardOffsetX;
    const vboy = (this._root.status.boardOffsetY) * 0.3 + this._root.status.boardScrollY;
    //
    const orb = this.status.orbit;
    const pos = this.objects.g.position;
    let vx = this._toRough(pos.x + Math.cos(orb.direction) * orb.force);
    let vy = this._toRough(pos.y + Math.sin(orb.direction) * orb.force);
    let vz = this._toRough(pos.z + (va * ((-this.status.z - vboz) - pos.z / 12)));
    //
    if (orb.force > 0) orb.force = this._toRough(orb.force - 0.2);
    vx += va * ((orb.destination.x - vx + vbox) / 12);
    vy += va * ((orb.destination.y - vy + vboy) / 12);
    //
    if (orb.twist > orb.twistMin) orb.twist = this._toRough(orb.twist - 0.01);
    if (orb.twist < -orb.twistMin) orb.twist = this._toRough(orb.twist + 0.01);
    //
    this.objects.g.position.set(this._toRough(vx), this._toRough(vy), vz);
    //
    const rotation = this.objects.g.rotation;
    this.objects.g.rotation.set(rotation.x, rotation.y, rotation.z + orb.twist);
  }

  release(direction, force, twist, offsetX = 0, offsetY = 0) {
    const pos = this.hold.target1.getWorldPosition(new THREE.Vector3);
    this.objects.g.position.set(pos.x + offsetX, pos.y + offsetY, pos.z - Number(0.1));
    this.hold.active = false;
    this.status.orbit.direction = direction;
    this.status.orbit.force = force;
    this.status.orbit.twist = twist;
  }

  setDestination(param) {
    this.status.orbit.destination = param;
  }

  setDelete() {
    setSequence([{
      duration: 500, // ボール位置移動
      parameters: [
        [this.objects.g, "scale.x", 0.1],
        [this.objects.g, "scale.y", 0.1],
      ],
      func: () => {
        this.objects.g.remove(this.objects.shape);
        this._scene.remove(this.objects.g);
        this.objects.shape.material.dispose();
        this.objects.shape.geometry.dispose();
        this.active = false;
      }
    }]);
  }

  _initBody() {
    this.objects.g = new THREE.Group();
    const size = (this.status.size / 10);
    //
    // FACEジオメトリー作成
    this.objects.shape = new THREE.Mesh(this.objects.geometry, this.objects.material);
    this.objects.shape.position.set(0, 0, 0);
    this.objects.shape.scale.set(size, size, 1);
    this.objects.g.add(this.objects.shape);
    this.objects.g.position.set(this.status.x, this.status.y, -10);
    if (this.status.visible === false) this.objects.g.scale.set(0.1, 0.1, 1);
    this.objects.g.visible = this.status.visible;
    this._scene.add(this.objects.g);
  }

  _toRough(value) {
    return Math.floor(value * 1000) / 1000;
  }
}