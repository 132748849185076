import Stage from './stage'; //eslint-disable-line
import Anago from './anago'; //eslint-disable-line
import Materials from './materials'; //eslint-disable-line
import PlotBoard from './plotboard'; //eslint-disable-line
import Ball from './ball'; //eslint-disable-line
import Background from './background'; //eslint-disable-line
import {
  svgToShape
} from './common'; //eslint-disable-line
import Geometrys from './geometrys'; //eslint-disable-line
/* global TWEEN, THREE */

window.addEventListener("load", function () {
  window.anago = new AnagoAnim((!window.anago) ? null : window.anago);
});

class AnagoAnim {
  constructor(page) {
    this.actors = {
      d: [],
      areaX: 400,
      max: 16,
      count: 0,
      positionListZ: [],
      positionListX: []
    };
    this.balls = [];
    //
    this.count = 0;
    //
    this.status = {
      pageId: (page) ? page : "top",
      ballNum: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      boardOffsetZ: 0,
      boardOffsetY: 0,
      boardScrollY: 0,
      mobile: (this._getWindowSize().x >= 768) ? false : true,
      scrollTop: 0,
      scrollStep: 0,
      scrollChanged: false
    };
    //
    this.stage = new Stage(this);
    this.materials = new Materials();
    this.geometrys = new Geometrys();
    this.plotBoard = new PlotBoard(this);
    //
    this.setAnagoArea();
    this.initActorPositions();
    //
    this.plotBoard.init()
      .then(() => {
        let defaultNum = 200;
        if (this.status.pageId === "top") defaultNum = 160;
        if (this.status.pageId === "other") defaultNum = 0;
        this.plotBoard.resetPos();
        for (let i = 0; i < defaultNum; i++) {
          this.createBall({
            color: this.materials.getRandomColorId(),
            visible: true
          });
        }
        //
        this.background = new Background(this);
        setInterval(() => this.update(), 40);
      });

    //
    window.addEventListener("scroll", () => {
      this.status.scrollChanged = true;
      this.status.scrollTop = document.scrollingElement.scrollTop;
    }, false);
  }

  initActorPositions() {
    for (let i = 0; i < 20; i++) {
      this.actors.positionListZ.push(i * 5 - 100);
      this.actors.positionListX.push(i - 10);
    }
  }

  update() {
    this.background.update();
    this.updateAnagos();
    this.updateBalls();
    //
    TWEEN.update();
    this.stage.update();
    this.count++;
  }

  updateAnagos() {
    if (this.status.scrollTop < 100) {
      if (this.status.scrollStep !== 0) {
        this.status.scrollStep = 0;
        this.actors.d.forEach((anago) => anago.setHide(false));
      }
    } else {
      this.status.scrollStep = 1;
      this.actors.d.forEach((anago) => anago.setHide(true));
    }
    //
    this.actors.d.forEach((anago) => anago.update());
    //
    if (this.status.pageId !== "top") return;
    if (this.status.scrollStep !== 0) return;
    if (this.count % 30 !== 0) return;
    if (this.actors.max > this.actors.d.length) {
      this.actors.d.push(new Anago(this));
      this.actors.count++;
    }
  }

  updateBalls() {
    this.balls.forEach((ball) => ball.update());
    //
    // プロット図形アップデート
    if (this.plotBoard.update() === true) {
      this.updateBallsDestination();
    }
    while (this.balls.length > 200) {
      this.balls[0].setDelete();
      this.balls.shift();
    }
  }

  updateBallsDestination() {
    this.plotBoard.resetPos();
    this.balls.forEach((ball) => {
      const type = Math.floor((11 - ball.status.size) / 2) + 1;
      ball.setDestination(this.plotBoard.getDestination(type));
    });
  }

  getRandomActor(setting) {
    const vid = (!setting) ? this.actors.count : setting.id;
    const pos = (!setting) ? this.getOnePosition("") : this.getOnePosition(setting);
    const physique = this.getOnePhysique();
    //
    return {
      id: vid,
      color: this.materials.getRandomColorId(), // 色
      eyes: this.geometrys.getRandomEyesId(), // 目
      hand: physique.hand, // 手
      posX: pos.posX, // 位置-X
      posZ: pos.posZ, // 位置-Y
      x: pos.x, // 位置-X
      z: pos.z + (vid / 5), // 位置-Z
      center: physique.center,  // 目の位置
      charm: this.geometrys.getRandomCharmId(), // チャームポイント
      blink: Math.floor(Math.random() * 50) + 50, // 瞬き頻度
      shoulder: physique.shoulder, // 肩の位置
      forehead: physique.forehead, // 
      height: physique.height, // 身長
      width: physique.width, // 横幅
      dominant: (Math.random() * 2 > 1) ? 1 : -1, // 利き手
      wait: [
        Math.floor(Math.random() * 50), // 出現前
        0, // 投げる前
        Math.floor(Math.random() * 100), // 投げた後
        Math.floor(Math.random() * 200), // 隠れた後
      ]
    };
  }

  getOnePhysique() {
    // あなごの太さを決定(0.75〜1.5)
    let width = 1;
    const num1 = Math.floor(Math.random() * 1000) - 500;
    if (num1 > 450) width = 1 + Math.floor((num1 - 450) / 5) / 20;
    if (num1 < -450) width = 1 + Math.floor((num1 + 450) / 5) / 40;
    //
    // あなごの背の高さ決定
    const height = Math.floor((Math.random() * 10 - 10) * 100) / 100;
    //
    // あなごの手の形 = 投げ方を決定
    const hand = this.geometrys.getRandomHandId();
    //
    // 片手投げの場合の肩の位置を決定
    let shoulder = 0;
    if (hand === 1) {
      if (width < 1) shoulder = (1 - width) * 10; // 細いあなご
      if (width > 1) shoulder = (width - 1) * -3; // 太いあなご
    }
    //
    return {
      height: height,
      width: width,
      hand: hand,
      shoulder: shoulder,
      center: 22 + width * 4
    };
  }

  getOnePosition(setting) {
    // X軸の位置を空きスペースから選択
    if (setting !== "") this.actors.positionListX.push(setting.posX);
    var num1 = Math.floor(Math.random() * this.actors.positionListX.length);
    const position1 = Number(this.actors.positionListX[num1]);
    this.actors.positionListX.splice(num1, 1);
    //
    // Y軸の位置を空きスペースから選択
    if (setting !== "") this.actors.positionListZ.push(setting.posZ);
    var num2 = Math.floor(Math.random() * this.actors.positionListZ.length);
    const position2 = Number(this.actors.positionListZ[num2]);
    this.actors.positionListZ.splice(num2, 1);
    //
    return {
      posX: position1,
      x: Math.floor(position1 / 20 * (this.actors.areaX * 0.8)),
      posZ: position2,
      z: position2 - 20
    };
  }

  createBall(setting) {
    let vx = Math.floor(Math.random() * 1000) - 500;
    let vy = Math.floor(Math.random() * 1000) - 500;
    if (vx > 0) vx += 500;
    if (vy > 0) vx -= 500;
    //
    const size = Math.floor(Math.random() * 6) + 6;
    const type = (!setting.type) ? Math.floor((11 - size) / 2) + 1 : setting.type;
    const set = {
      x: (setting.x) ? null : vx,
      y: (setting.x) ? null : vy,
      z: this._toRough(this.status.ballNum / 200),
      size: size,
      color: setting.color,
      scene: this.stage.scene,
      destination: this.plotBoard.getDestination(type),
      visible: (setting.visible) ? setting.visible : false,
      hold: (setting.hold) ? setting.hold : {
        active: false,
        target1: null,
        target2: null
      }
    };
    this.status.ballNum++;
    if (this.status.ballNum > 200) this.status.ballNum = 0;
    const ball = new Ball(this, set);
    this.balls.push(ball);
    return ball;
  }

  setBoardOffset(value) {
    this.status.boardOffsetX = value.x;
    this.status.boardOffsetY = value.y;
    this.status.boardOffsetZ = value.z;
  }

  setAnagoArea() {
    const size = this._getWindowSize();
    this.actors.areaX = Math.floor(size.x / 1920 * 400);
    //
    // 穴子の出現匹数を設定
    const max = Math.floor((size.x - 320) / 1600 * 8) + 4;
    this.actors.max = (max < 16) ? max : 16;
  }

  getScene() {
    return this.stage.scene;
  }

  _getWindowSize() {
    return {
      x: document.body.clientWidth,
      y: document.body.clientHeight
    };
  }

  _toRough(value) {
    return Math.floor(value * 1000) / 1000;
  }

  isActorActive(id) {
    return (id < this.actors.max) ? true : false;
  }

  isScrollChanged() {
    if (this.status.scrollChanged === true){
      this.status.scrollChanged = false;
      return true;
    }
    return false;
  }

  test(path) {
    this.obj = {};
    this.obj.body = new THREE.Group();
    const geometry = new THREE.ShapeGeometry(svgToShape(path, -10, 10, 0.16));
    const material1 = new THREE.MeshBasicMaterial({
      wireframe: true,
      color: 0x505050
    });
    const material2 = new THREE.MeshBasicMaterial({
      color: 0xaaaaaa
    });
    this.obj.shape1 = new THREE.Mesh(geometry, material1);
    this.obj.shape1.position.set(0, 0, 1.2);
    this.obj.body.add(this.obj.shape1);
    //
    this.obj.shape1 = new THREE.Mesh(geometry, material2);
    this.obj.shape1.position.set(0, 0, 1.1);
    this.obj.body.add(this.obj.shape1);
    //
    this.obj.body.scale.set(10, 10, 1);
    this.obj.body.position.set(0, 80, 0);

    this.stage.scene.add(this.obj.body);
  }
}