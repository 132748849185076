/* global THREE */

export default class Stage {
  constructor(root) {
    this._root = root;
    //
    this.status = {
      mouseX: window.innerWidth / 2,
      mouseY: window.innerHeight / 2,
      isCameraDirectionMoved: false
    };
    this.init();
    //
    this.updateCameraPosition();
    this._resize();
    //
    window.addEventListener("resize", () => this._resize());
    window.addEventListener("mousemove", (value) => this._setCameraDirection(value));
    //
    this._setBoardOffset();
    //this.testGuide();
  }

  // シーン、カメラオブジェクトの生成
  init() {
    // シーン生成
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xffffff);
    //
    // カメラ生成
    const aspect = window.innerWidth / window.innerHeight;
    this.camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
    this.camera.position.y = 100;
    this.camera.position.z = 150;
    //
    // レンダラー生成
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.domElement.setAttribute("id", "top-animation");
    let plane = new THREE.Plane(new THREE.Vector3(0, 0.01, 0), 10);
    this.renderer.clippingPlanes.push(plane);
    //
    // クリップ用の床面を生成
    const geometry = new THREE.PlaneGeometry(400, 100, 1, 0);
    const material = new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
      color: 0x999999
    });
    const body = new THREE.Mesh(geometry, material);
    body.rotation.set(Math.PI / 2, 0, 0);
    body.position.set(0, 0, -50);
    this.scene.add(body);
    //
    this.renderer.render(this.scene, this.camera); // renderしてからappend
    document.body.appendChild(this.renderer.domElement);
  }

  update() {
    if (this.isCameraDirectionMoved === true) this.updateCameraPosition();
    this.renderer.render(this.scene, this.camera);
  }

  // カメラの向きを設定
  _setCameraDirection(value) {
    if (this._root.status.mobile === true) return;
    this.status.mouseX = value.clientX;
    this.status.mouseY = value.clientY;
    this.isCameraDirectionMoved = true;
  }

  updateCameraPosition() {
    const size = this._getWindowSize();
    const posX = this._toRough((this.status.mouseX / size.x - 0.5) / 5);
    const posY = this._toRough((this.status.mouseY / size.y - 0.5) / 5);
    const magY = size.y / 1080;
    const cY = (115 * magY) - posY;
    //
    this.camera.position.set(-posX, cY, 150 * magY);
    this.camera.quaternion.y = posX;
    this.camera.quaternion.x = posY;
    //
    this.isCameraDirectionMoved = false;
  }

  _resize() {
    const size = this._getWindowSize();
    //
    // レンダラーのサイズ
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(size.x, size.y);
    //
    // カメラのアスペクト比
    this.camera.aspect = size.x / size.y;
    this.camera.updateProjectionMatrix();
    this.updateCameraPosition();
    this.renderer.render(this.scene, this.camera);
    //
    this._setBoardOffset();
    this._root.setAnagoArea();
  }

  // PC表示の時のプロットボードのオフセット位置を設定する。
  _setBoardOffset() {
    // プロット図形のZ値を算出 - W1920-Z=0として横幅が0pxの場合に108から480px = 3で割る
    const size = this._getWindowSize();
    this._root.status.mobile = (size.x >= 768) ? false : true;
    const vy = (size.x >= 768) ? 230 : 160;
    const vx = (size.x >= 768) ? 0 : 80;
    let z = 108;
    while (size.x > 0) {
      const x1 = (size.x < 480) ? size.x : 480;
      z = z / ((x1 / 480 * 2) + 1);
      size.x -= 480;
    }
    this._root.setBoardOffset({
      x: vx,
      y: Math.floor(size.y / 1080 * vy),
      z: Math.floor(z)
    });
  }

  // 位置調整用ガイドを表示する
  testGuide() {
    this.testGroup = new THREE.Group();
    this.scene.add(this.testGroup);
    const makePlane = (x, y, z, w, h, color, group) => {
      const geometry = new THREE.PlaneGeometry(w, h, 2);
      const material = new THREE.MeshBasicMaterial({
        color: color,
        side: THREE.DoubleSide
      });
      const plane = new THREE.Mesh(geometry, material);
      plane.position.set(x, y, z);
      if (group === true) {
        this.testGroup.add(plane);
      } else {
        this.scene.add(plane);
      }
      return plane;
    };
    makePlane(160, 115, 0, 1, 170, 0xff0000, true);
    makePlane(-160, 115, 0, 1, 170, 0xff0000, true);
    makePlane(0, 30, 0, 320, 1, 0xff0000, true);
    makePlane(0, 200, 0, 320, 1, 0xff0000, true);
    makePlane(0, 230, 0, 640, 1, 0x0000ff, false);
    makePlane(0, 460, 0, 640, 1, 0x0000ff, false);
    makePlane(0, 0, 0, 640, 1, 0x0000ff, false);
    const feeld = makePlane(0, 1, -50, 200, 100, 0x00ffff, false);
    feeld.rotation.set(Math.PI / 2, 0, 0);
  }

  _toRough(value) {
    return Math.floor(value * 1000) / 1000;
  }

  _getWindowSize() {
    return {
      x: window.innerWidth,
      y: window.innerHeight
    };
  }
}