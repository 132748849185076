import { svgToShape } from './common'; //eslint-disable-line
/* global THREE */
export default class Geometrys {
  constructor() {
    //
    this.hand = this._initHandGeometry();
    this.charms = this._initCharmGeometry();
    this.eyes = this._initEyesGeometry();
    //
    this.head = new THREE.ParametricGeometry((u, v, target) => {
      u *= Math.PI;
      v *= 1 * Math.PI;
      const x = 9.5 * Math.sin(u) * Math.cos(v);
      const y = 9.5 * Math.sin(u) * Math.sin(v);
      const z = 0;
      target.set(x, y, z);
    }, 2, 32);
  }

  // ハンドタイプの設定（アニメーションはanago.js側で設定している）
  _initHandGeometry() {
    const geometrys = [];
    [ // パスデータ指定部分（idは0~）
      "M57.7,45.2c-1.6-7.3-1.6-18-7.2-23.5c-3.3-3.2-8.7-2.4-11,1.4c-4.4,7.3-0.7,19,1.1,26.7c0.3,1.3,0.8,2.4,1.5,3.3H19.6c0.1-0.7,0.1-1.4,0.1-2.1c-0.6-6-1.1-11.4-0.6-17.4c0.5-5.4,1.3-9.7-1.3-14.6c-0.7-1.3-2.3-1.8-3.6-1.5C1,20.8,0.8,42.9,4.5,53.2c2.1,5.7,10.2,6.9,13.7,3.1l30.8,0C54,55.9,59.1,51.6,57.7,45.2z",
      "M40.6,51.1c-0.6-6-1.1-11.4-0.6-17.4c0.5-5.4,1.3-9.7-1.3-14.6c-0.7-1.3-2.3-1.8-3.6-1.5c-13.2,3.2-13.4,25.2-9.7,35.5C28.2,60.7,41.5,60.4,40.6,51.1z",
      "M30,50l1,1l1,-1z"
    ].forEach((path) => {
      geometrys.push(new THREE.ShapeGeometry(svgToShape(path, -10, 10, 0.32)));
    });
    return {
      setting: [ // 設定データ [パスデータID, 出現率]
        [0, 5],
        [1, 5],
        [2, 1],
      ],
      stock: [],
      data: geometrys
    };
  }

  getRandomHandId() {
    if (this.hand.stock.length === 0) {
      this.hand.setting.forEach((eye, num) => {
        for (let i = 0; i < eye[1]; i++) {
          this.hand.stock.push(num);
        }
      });
    }
    const num = Math.floor(Math.random() * this.hand.stock.length);
    const id = this.hand.stock[num];
    this.hand.stock.splice(num, 1);
    return id;
  }

  getHandFromId(id) {
    return this.hand.data[id];
  }

  // チェームポイントの設定
  _initCharmGeometry() {
    const geometrys = [];
    [ // パスデータ指定部分（idは0~）
      "M18.4,47.5c1.7-3,3.7-6,6.5-8.1c0.8-0.6,3.1-2.3,4.8-2.5c0.6-0.3,1.4-0.5,2.1-0.5c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.7,0,1.5,0.2,2.1,0.5c1.7,0.1,4,1.8,4.8,2.5c2.8,2.1,4.8,5.1,6.5,8.1c0.3,0.5-0.2,1.1-0.7,0.9c-3-1-6-2.3-8.5-4.3c-1-0.8-2.1-1.7-2.9-2.7c0,0-0.1-0.1-0.1-0.2c-0.5-0.4-0.9-0.8-1.1-1.2c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0.1c-0.3,0.5-0.7,0.9-1.1,1.2c0,0.1-0.1,0.1-0.1,0.2c-0.8,1.1-1.9,2-2.9,2.7c-2.5,2-5.5,3.3-8.5,4.3C18.6,48.6,18.2,48,18.4,47.5z"
    ].forEach((path) => {
      geometrys.push(new THREE.ShapeGeometry(svgToShape(path, -10, 10, 0.32)));
    });
    return {
      setting: [ // 設定データ [パスデータID, 出現率, 色]
        [null, 100, null], // チャームなし
        [0, 1, 0], // ヒゲ
      ],
      stock: [],
      data: geometrys
    };
  }

  getRandomCharmId() {
    if (this.charms.stock.length === 0) {
      this.charms.setting.forEach((eye, num) => {
        for (let i = 0; i < eye[1]; i++) {
          this.charms.stock.push(num);
        }
      });
    }
    const num = Math.floor(Math.random() * this.charms.stock.length);
    const id = this.charms.stock[num];
    this.charms.stock.splice(num, 1);
    return id;
  }

  getCharmSetFromId(id) {
    const setting = this.charms.setting[id];
    return {
      geometry: (setting[0] !== null) ? this.charms.data[setting[0]] : null,
      material: setting[2]
    };
  }

  // 目の設定
  _initEyesGeometry() {
    const data =[];
    const colors = [];
    //
    [0xffffff, 0x000000].forEach((color)=>{
      colors.push(new THREE.MeshBasicMaterial({
        side: THREE.FrontSide,
        color: color
      }));
    });
    [ // パスデータ指定部分（idは0~）
      "M42.3,32c0,2.7-2.1,4.9-4.7,4.9s-4.7-2.2-4.7-4.9s2.1-4.9,4.7-4.9S42.3,29.3,42.3,32z",
      "M26.4,27.1c-2.6,0-4.7,2.2-4.7,4.9s2.1,4.9,4.7,4.9s4.7-2.2,4.7-4.9S29,27.1,26.4,27.1z",
      "M30.1,29.9c0,1.8-1.5,3.3-3.4,3.3s-3.4-1.5-3.4-3.3c0-1,0.4-1.8,1.1-2.4c0.6-0.3,1.3-0.5,2-0.5c1.3,0,2.5,0.5,3.3,1.4C30,28.9,30.1,29.4,30.1,29.9z",
      "M40.5,28.1c-0.8-0.7-1.8-1.1-2.9-1.1c-0.9,0-1.8,0.3-2.5,0.7c-0.5,0.6-0.8,1.3-0.8,2.1c0,1.8,1.5,3.3,3.4,3.3c1.9,0,3.4-1.5,3.4-3.3C41.1,29.3,40.9,28.7,40.5,28.1z",
      "M21.4,27.1h-2.1c0,3.4,2.6,6.2,5.8,6.2c3.2,0,5.8-2.8,5.8-6.2h-2.3H21.4z",
      "M42.4,27.1h-7.2h-2.1c0,3.4,2.6,6.2,5.8,6.2c3.2,0,5.8-2.8,5.8-6.2H42.4z",
      "M25,30.3c1.7,0,3.1-1.4,3.6-3.3h-7.2C21.9,29,23.3,30.3,25,30.3z",
      "M38.8,30.3c1.7,0,3.1-1.4,3.6-3.3h-7.2C35.7,29,37.1,30.3,38.8,30.3z",
      "M42.6,27.6c-0.2-0.2-0.4-0.4-0.5-0.6c-0.9-1-2.1-1.8-3.3-2c-1.3-0.2-2.5,0.4-3.5,1.3c-1.1,1.1-2,2.6-2.6,4c-0.1,0.2,0,0.4,0.1,0.5c0,0.1,0,0.2,0.1,0.3c1.7,2.4,4.3,5.5,7.1,4c0.6-0.3,2.8-1.9,3.7-3.4c0.2-0.2,0.4-0.5,0.6-0.8c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.2C44.6,29.8,43,28,42.6,27.6z",
      "M29.2,26.9c-0.9-1-2.1-1.8-3.3-2c-1.3-0.2-2.5,0.4-3.5,1.3c-1.1,1.1-2,2.6-2.6,4c-0.1,0.2,0,0.4,0.1,0.5c0,0.1,0,0.2,0.1,0.3c1.7,2.4,4.3,5.5,7.1,4c0.6-0.3,2.8-1.9,3.7-3.4c0.2-0.2,0.4-0.5,0.6-0.8c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.2c0.2-0.8-1.4-2.6-1.7-3C29.5,27.3,29.4,27.1,29.2,26.9z",
      "M38.6,32.6c1.9,0,3.5-2.5,3.5-5.5c0-0.1,0-0.1,0-0.2c-0.9-1-2.1-1.8-3.3-2c-1.3-0.2-2.5,0.4-3.5,1.3c0,0.3,0,0.6,0,0.9C35.2,30.1,36.7,32.6,38.6,32.6z",
      "M25.7,32.6c1.9,0,3.5-2.5,3.5-5.5c0-0.1,0-0.1,0-0.2c-0.9-1-2.1-1.8-3.3-2c-1.3-0.2-2.5,0.4-3.5,1.3c0,0.3,0,0.6,0,0.9C22.3,30.1,23.8,32.6,25.7,32.6z",
      "M28.6,25c-0.4-0.1-0.7-0.2-1.1-0.2c-2.2,0-4,2-4.4,4.7c-0.1,0.4-0.1,0.8-0.1,1.2c0,3.3,2,5.9,4.5,5.9s4.5-2.7,4.5-5.9C32,28,30.5,25.7,28.6,25z",
      "M37.2,24.9c-0.2,0-0.4-0.1-0.7-0.1c-2.3,0-4.1,2.2-4.5,5c0,0.3-0.1,0.6-0.1,0.9c0,3.3,2,5.9,4.5,5.9s4.5-2.7,4.5-5.9C41.1,27.8,39.4,25.3,37.2,24.9z",
      "M25.9,32.4c1.7,0,3.1-2.2,3.1-4.8c0-0.9-0.2-1.8-0.5-2.5c-0.4-0.1-0.7-0.2-1.1-0.2c-2.2,0-4,2-4.4,4.7C23.5,31.2,24.6,32.4,25.9,32.4z",
      "M34.7,32.4c1.7,0,3-2.2,3-4.8c0-1-0.2-1.9-0.5-2.6c-0.2,0-0.4-0.1-0.7-0.1c-2.3,0-4.1,2.2-4.5,5C32.6,31.4,33.6,32.4,34.7,32.4z",
      "M28.3,25c-0.4-0.1-0.7-0.1-1.1-0.1c-2.1,0-3.8,1-4.3,2.3c-0.1,0.3-0.2,0.6-0.2,0.9c0,1.7,2,3.2,4.5,3.2s4.5-1.4,4.5-3.2C31.7,26.5,30.2,25.3,28.3,25z",
      "M38.1,25c-0.4-0.1-0.8-0.2-1.3-0.2c-2,0-3.6,0.9-4.2,2.1c-0.2,0.3-0.3,0.7-0.3,1.1c0,1.7,2,3.2,4.5,3.2s4.5-1.4,4.5-3.2C41.3,26.6,40,25.4,38.1,25z",
      "M25.5,28.5c1.7,0,3-1.1,3-2.6c0-0.3-0.1-0.7-0.2-1c-0.4-0.1-0.7-0.1-1.1-0.1c-2.1,0-3.8,1-4.3,2.3C23.4,27.9,24.3,28.5,25.5,28.5z",
      "M35.3,28.5c1.7,0,3-1.1,3-2.6c0-0.3-0.1-0.6-0.2-0.9c-0.4-0.1-0.8-0.2-1.3-0.2c-2,0-3.6,0.9-4.2,2.1C33.1,27.9,34.1,28.5,35.3,28.5z"
    ].forEach((path) => {
      data.push(new THREE.ShapeGeometry(svgToShape(path, -10, 10, 0.32)));
    });
    //
    return {
      setting:[
        [0, 1, 2, 3, 24], // 通常
        [4, 5, 6, 7 ,1], // 三日月
        [8, 9, 10, 11 ,1], // アーモンド
        [12, 13, 14, 15 ,1], // 大豆
        [16, 17, 18, 19 ,1], // 大豆（小）
      ],
      stock: [],
      data: data,
      colors: colors
    };
  }

  getRandomEyesId() {
    if (this.eyes.stock.length === 0) {
      this.eyes.setting.forEach((eye, num) => {
        for (let i = 0; i < eye[4]; i++) {
          this.eyes.stock.push(num);
        }
      });
    }
    const num = Math.floor(Math.random() * this.eyes.stock.length);
    const id = this.eyes.stock[num];
    this.eyes.stock.splice(num, 1);
    return id;
  }

  getEyesSetFromId(id) {
    const group = new THREE.Group();
    this.eyes.setting[id].forEach((dataId,key)=>{
      if (key === 4) return;
      const geometry = this.eyes.data[dataId];
      const mesh = new THREE.Mesh(geometry, this.eyes.colors[Math.floor(key/2)]);
      mesh.position.set(0,0,0.1 + Math.floor(key/2) * 0.01);
      group.add(mesh);
    });
    return group;
  }
}