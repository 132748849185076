/* global THREE */

export default class Background {
  constructor(root) {
    this._root = root;
    this.objects = [];
    for (let i = 0; i < 20; i++) {
      this.createObject();
    }
  }

  createObject() {
    const size = Number(0.6) + Math.floor(Math.random() * 60) / 120;
    //
    const rx = Math.floor(Math.random() * 15) + 5;
    const ry = Math.floor(Math.random() * 15) + 5;
    const rz = Math.floor(Math.random() * 15) + 5;
    //
    const dx = Math.floor((Math.random() * Math.PI * 2000)) / 1000;
    const dy = Math.floor((Math.random() * Math.PI * 2000)) / 1000;
    const dz = Math.floor((Math.random() * Math.PI * 2000)) / 1000;
    //
    const center = new THREE.Group();
    center.position.set(0, 90, 50);
    center.rotation.set(dx, dy, dz);
    //
    const geometry = this._root.plotBoard.getRandomPlotGeometry();
    const color = this._root.materials.getRandomColorId();
    const material = this._root.materials.getColorFromId(color);
    //
    // FACEジオメトリー作成
    const shape = new THREE.Mesh(geometry, material);
    shape.position.set(0, 0, -200);
    shape.scale.set(size, size, 1);
    center.add(shape);
    //
    this._root.stage.scene.add(center);
    this.objects.push({
      shape: shape,
      center: center,
      move: [Number(rx), Number(ry), Number(rz)]
    });
  }

  update() {
    let scrollAdj = Math.floor(this._root.status.scrollTop / 5 + 10) / 10;
    if (scrollAdj > 10) scrollAdj = 10;
    this.objects.forEach((object) => {
      const pix = Math.floor(Math.PI * 2 * 1000);
      let pos = [
        Number(object.center.rotation.x),
        Number(object.center.rotation.y),
        Number(object.center.rotation.z),
      ];
      pos.forEach((value, i) => {
        pos[i] = Number(value) * 1000 + Number(object.move[i]) / scrollAdj;
        if (pos[i] > pix) pos[i] -= pix;
        if (pos[i] < -pix) pos[i] += pix;
        pos[i] = pos[i] / 1000;
      });
      object.center.rotation.set(pos[0], pos[1], 0);
    });
  }
}